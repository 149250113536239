import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import { JWTPayloadProperties } from '../constants';

const NOT_VALID_VALUES = [null, undefined, '', []];

const cookieOptions = () => ({
  sameSite: 'None',
  secure: true,
});

const validStorageValue = (value, cb) => {
  if (NOT_VALID_VALUES.includes(value)) return;
  cb();
};

const locStorage = {
  setItem: (key, value) => {
    validStorageValue(value, () => {
      localStorage.setItem(key, value);
    });
  },
  getItem: key => localStorage.getItem(key) || '',
  removeItem: key => localStorage.removeItem(key),
  getItemWithNameContains: name => {
    const keys = Object.keys(localStorage);
    const keyFound = keys.find(key => key.includes(name));
    return keyFound ? localStorage.getItem(keyFound) : '';
  },
  getItemWithNameContainsConstraints: constraints => {
    const keys = Object.keys(localStorage);
    const keyFound = keys.find(key => constraints.every(constraint => key.includes(constraint)));
    return keyFound ? localStorage.getItem(keyFound) : '';
  },
};

const getSessionToken = () => {
  const lastAuthUser = locStorage.getItemWithNameContains('LastAuthUser');
  if (!lastAuthUser) return '';
  const token = locStorage.getItemWithNameContainsConstraints(['idToken', lastAuthUser]);
  return token;
};

const cookieStorage = {
  setItem: (key, value) => {
    validStorageValue(value, () => {
      Cookies.set(key, value, cookieOptions());
    });
  },
  getItem: key => Cookies.get(key, cookieOptions()) || '',
  removeItem: key => Cookies.remove(key, cookieOptions()),
  getSessionToken: () => {
    const jwtSessionToken = getSessionToken();
    return jwtSessionToken;
  },
  getRole: () => {
    const jwtSessionToken = getSessionToken();
    if (!jwtSessionToken) return '';
    const decodedToken = jwtDecode(jwtSessionToken);
    const roles = decodedToken[JWTPayloadProperties.GROUPS] || [];
    return roles[0];
  },
};

const storage = (type = 'locStorage') => {
  const types = {
    locStorage,
    cookieStorage,
  };
  if (typeof Storage !== 'undefined') {
    return types[type];
  }
  return type.cookieStorage;
};

export default storage;
