import React from 'react';

import styles from './SplashScreen.module.scss';

const SplashScreen = () => (
  <div className={styles.root}>
    <div className={styles.logoStrapline}/>
  </div>
);

export default SplashScreen;
