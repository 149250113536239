import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { FormFieldWrapper } from '../../../common';
import styles from './UserDetailsModal.module.scss';

const DATA_CY = 'user-details-modal';

const UserDetailsModal = ({ modalOpen, onAccept, onCancel, email }) => {
  const alert = useAlert();
  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .test('pwd-is-not-username', 'Password cannot be the same as username', value => value !== email)
      .matches(/^(?=^.{12,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
        message: 'Incorrect format - min 12 characters, 1 uppercase, 1 lowercase and 1 symbol',
        excludeEmptyString: false,
      })
      .required('Required'),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      .required('Required'),
    oldPassword: Yup.string()
      .matches(/^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
        message: 'Incorrect format - min 8 characters, 1 uppercase, 1 lowercase and 1 symbol',
        excludeEmptyString: false,
      })
      .required('Required'),
  });

  const changePassword = async authData => {
    try {
      const { oldPassword, newPassword } = authData;
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      onAccept();
      alert.show('Password correctly updated');
    } catch (error) {
      onAccept();
      alert.error(`Error on login process: ${error.message}`);
    }
  };

  return (
    <Modal
      className={styles.modalCard}
      closeOnDimmerClick={true}
      data-cy={DATA_CY}
      open={modalOpen}
    >
      <Modal.Header>
        {'Change password'}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className={styles.description}>
            {'Introduce your current password and the new one'}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Content>
        <Formik
          enableReinitialize={true}
          initialValues={defaultValues}
          validationSchema={ChangePasswordSchema}
          onSubmit={async (values, formikBag) => {
            await changePassword(values);
            formikBag.changePassword(defaultValues);
          }}
        >
          {({ handleSubmit }) => (
            <Form
              className={styles.form}
              data-cy={`${DATA_CY}-form`}
              onSubmit={handleSubmit}
            >
              <FormFieldWrapper
                label="Old password"
                name="oldPassword"
                type="password"
              />
              <FormFieldWrapper
                label="New password"
                name="newPassword"
                type="password"
              />
              <FormFieldWrapper
                label="Confirm new password"
                name="newPasswordConfirmation"
                type="password"
              />
              <div className={styles.buttonWrapper}>
                <Button onClick={onCancel}>
                  {'Cancel'}
                </Button>
                <Button primary type="submit">
                  {'Accept'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

UserDetailsModal.defaultProps = {
};

UserDetailsModal.propTypes = {
  email: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UserDetailsModal;
