import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { errorAPI, multipartAPI } from '../../../../api';
import { DateTimeFormat, Entity } from '../../../../constants';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import { trimValidation } from '../../../../utils/validationHelpers';
import { FormFieldDateTimeWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../common';
import styles from './DashboardAuditorApplicationFieldsModal.module.scss';

const auditorApplicationValidationSchema = Yup.object().shape({
  emergency_name: Yup.string()
    .required('Please provide full details for your emergency contact before progressing')
    .typeError('Please provide full details for your emergency contact before progressing'),
  emergency_phone: Yup.string()
    .required('Please provide full details for your emergency contact before progressing')
    .typeError('Please provide full details for your emergency contact before progressing'),
  emergency_relationship: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Please provide full details for your emergency contact before progressing')
    .typeError('Please provide full details for your emergency contact before progressing'),
  other_pronoun: Yup.string().when('preferred_pronouns', {
    is: 'Other',
    then: Yup.string()
      .max(20, 'Please enter a maximum of 20 characters')
      .required('Please select your preferred pronouns before continuining')
      .typeError('Please select your preferred pronouns before continuining'),
  }),
  preferred_first_name: Yup.string()
    .test('trim', 'Please remove spaces before/after the preferred first name', trimValidation)
    .required('Please enter your preferred first name')
    .typeError('Please enter your preferred first name'),
  preferred_last_name: Yup.string()
    .test('trim', 'Please remove spaces before/after the preferred last name', trimValidation)
    .required('Please enter your preferred last name')
    .typeError('Please enter your preferred last name'),
  preferred_pronouns: Yup.string().trim().ensure().min(1, 'Required'),
  transport_available: Yup.string().trim().ensure().min(1, 'Required')
    .typeError('Required'),
  visa_expiration: Yup.date().when('nationality', {
    is: value => ['IE', 'GB'].includes(value),
    then: Yup.date().nullable(),
    otherwise: Yup.date().typeError('Invalid date').min(new Date(), 'Visa cannot be expired.')
      .when('visa_expiration_date_required', {
        is: true,
        then: Yup.date().typeError('Please provide the expiration date of your visa').required('Please provide the expiration date of your visa'),
        otherwise: Yup.date().nullable(),
      }) }),
  visa_expiration_date_required: Yup.boolean()
    .when('nationality', {
      is: value => ['IE', 'GB'].includes(value),
      then: Yup.boolean().nullable(),
      otherwise: Yup.boolean()
        .required('Please specify if your visa has an expiration date')
        .typeError('Please specify if your visa has an expiration date') }),
});

const DashboardAuditorApplicationFieldsModal = ({ onAccept, userInfo }) => {
  const pronounsOptionsSelector = state => enumOptionsSelector(state, 'pronouns_type');
  const pronounsOptions = useSelector(pronounsOptionsSelector).map(option => ({
    value: option.value,
    text: option.value,
  }));
  const accessToRelationshipOptionsSelector = state => enumOptionsSelector(state, 'relationship_type');
  const accessToRelationshipOptions = useSelector(accessToRelationshipOptionsSelector).map(option => ({
    value: option.value,
    text: option.value,
  }));
  const transportAvailableOptionSelector = state => enumOptionsSelector(state, 'transport_available_type');
  const transportAvailableOption = useSelector(transportAvailableOptionSelector);
  const initialValues = {
    ...userInfo,
    other_pronoun: userInfo.other_pronoun ? userInfo.other_pronoun : '',
  };
  const hasVisaExpirationDate = [
    { key: 'yes', value: true, text: 'Yes' },
    { key: 'no', value: false, text: 'No' },
  ];

  const isNonGBOrIrelandWithoutVisa = (!['GB', 'IE'].includes(userInfo.nationality)) && !userInfo.visa_expiration;

  const { mutate: editAuditorAccount } = useMutation(multipartAPI.update, {
    onSuccess: () => {
      onAccept();
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const updateAuditorInfo = data => {
    const values = data.preferred_pronouns === 'Other' ? data : {
      ...data,
      other_pronoun: '',
    };
    const formData = new FormData();
    const { identification, image, passport, visa, ...filteredValues } = values;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    return editAuditorAccount({
      entity: Entity.AUDITORS,
      entityId: 'me',
      payload: formData,
      version: 'v2',
    });
  };

  return (
    <Modal className="auditorFieldsModal" closeOnDimmerClick={true} open={true} size="tiny">
      <Modal.Header>
        {'Information Required'}
      </Modal.Header>
      <Modal.Content>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validateOnChange={true}
          validationSchema={auditorApplicationValidationSchema}
          onSubmit={updateAuditorInfo}
        >
          {({ handleSubmit, values }) => (
            <>
              <Modal.Content className={styles.content}>
                <Form onSubmit={handleSubmit}>
                  <div>
                    <span className={styles.titleQuestion}>
                      {'Please select your preferred pronouns:'}
                    </span>
                    <FormFieldSelectWrapper
                      required
                      name="preferred_pronouns"
                      options={pronounsOptions}
                      placeholder="Select an option"
                    />
                    {values.preferred_pronouns === 'Other' && (
                      <FormFieldWrapper name="other_pronoun" placeholder="Other pronoun" />
                    )}
                  </div>

                  <div>
                    <div className={styles.titleQuestion}>
                      {'Please enter your preferred name.'}
                    </div>
                    <div className={styles.italicNote}>
                      {
                        'This is what we shall refer to you as should your application be successful. This can also include shortened names (i.e. Thomas - Tom, Loretto - Lottie).'
                      }
                    </div>
                    <div className={styles.italicNote}>
                      {'If you do not have a preferred name, please reinput your first and last name as above.'}
                    </div>
                    <FormFieldWrapper required name="preferred_first_name" placeholder="First Name" />
                    <FormFieldWrapper required name="preferred_last_name" placeholder="Last Name" />
                  </div>

                  <div>
                    {isNonGBOrIrelandWithoutVisa && (
                      <>
                        <span className={styles.titleQuestion}>
                          {'Does your evidence of right to work have an expired date?'}
                        </span>
                        <FormFieldSelectWrapper
                          required
                          name="visa_expiration_date_required"
                          options={hasVisaExpirationDate}
                          placeholder="Select an option"
                        />
                        {values.visa_expiration_date_required && <>
                          <span className={styles.titleQuestion}>
                            {'Specify the expiration date of your visa'}
                          </span>
                          <Form.Field width={16}>
                            <FormFieldDateTimeWrapper
                              format={DateTimeFormat.DAY_MONTH_YEAR}
                              name="visa_expiration"
                              time={false}
                            />
                          </Form.Field>
                        </>}
                      </>)}
                  </div>

                  <div>
                    <span className={styles.titleQuestion}>
                      {'Emergency contact'}
                    </span>
                    <span className={styles.italicTitleQuestion}>
                      {
                        'Your emergency contact would only be contacted in emergencies regarding your safety or wellbeing concerns.'
                      }
                    </span>
                    <FormFieldWrapper required name="emergency_phone" placeholder="Emergency contact phone number" />
                    <FormFieldWrapper required name="emergency_name" placeholder="Emergency contact name" />
                    <span className={styles.titleQuestion}>
                      {'Please select their relationship to you:'}
                    </span>
                    <FormFieldSelectWrapper
                      required
                      name="emergency_relationship"
                      options={accessToRelationshipOptions}
                      placeholder="Select an option"
                    />
                  </div>
                  <div className={styles.note}>
                    <span className={styles.titleQuestion}>
                      {'Transport available to you'}
                    </span>
                  </div>
                  <FormFieldSelectWrapper
                    required
                    name="transport_available"
                    options={transportAvailableOption}
                    placeholder="Select an option"
                  />
                  <div className={styles.note}>
                    {'You can always change these preferences in your account.'}
                  </div>
                  <Modal.Actions>
                    <Modal.Content className={styles.content}>
                      <Button
                        primary
                        content="Save and close"
                        type="submit"
                      />
                    </Modal.Content>
                  </Modal.Actions>
                </Form>
              </Modal.Content>
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

DashboardAuditorApplicationFieldsModal.propTypes = {
  userInfo: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default DashboardAuditorApplicationFieldsModal;
